@font-face {
  font-family: dirt;
  src: url(./fonts/Dirtyline.ttf);
}

@font-face {
  font-family: satoshi;
  src: url(./fonts/neumont/PPNeueMontreal-Book.ttf) format("truetype");
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: heading;
  src: url(./fonts/neumont/PPNeueMontreal-Medium.ttf) format("truetype");
}

@mixin flex-center($justify: center, $align: center) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
}

@mixin aboslute($top: 0, $left: 0, $right: 0, $bottom: 0) {
  position: absolute;
  top: $top;
  left: $left;
  transform: translate(-50%, -50%);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  width: 100%;
  font-family: satoshi;
  font-size: 16px;
  background-color: var(--bgl);
}

:root {
  --bgd: #0e0e0e;
  --bgl: #f9f9f9;
  --crimson: rgb(220, 20, 60);
  --teal : rgb(35, 235, 195);
  --btn: -100%;
  --strike-through: 0%;
  --clip: inset(100% 0 0 0);
}
*::selection {
  background-color: var(--crimson);
  color: var(--bgl);
  border-radius: 3rem;
}
body::-webkit-scrollbar {
  width: .5rem;
  background-color: #555;
}

body::-webkit-scrollbar-thumb {
  background-color: var(--crimson);
  border-radius: 1rem;
}

::-webkit-scrollbar:hover {
  background: #555;
}

#overlay {
  z-index: 2;
  position: fixed;
  inset: 0;
  background-color: transparent;
}

video,
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

a {
  text-decoration: none;
  color: white;
}

canvas.webgl {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  display: block;
  top: 0;
}

button#btn1 {
  filter: invert(100%);
  margin: 0;
  padding: 0.25em.5em;
  width: fit-content;
}

button.nav-btn {
  @include flex-center;
  gap: 1rem;
  outline: none;
  border: 1px solid white;
  background-color: transparent;
  position: relative;
  font-family: dirt;
  padding: 0.5em 1em;
  margin-top: 1rem;
  font-size: 1.2rem;
  border-radius: 3rem;
  overflow: hidden;
  // z-index: -2;
  filter: drop-shadow(0 0 1rem #f9f9f9);

  &:hover {
    p {
      color: rgb(0, 0, 0);
    }

    span {
      background-color: black;
      scale: 1;
    }

    i {
      transform: translate(0%, -0%);
    }
  }

  p {
    z-index: 2;
    color: white;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    bottom: var(--btn);
    // z-index: 1;
  }

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(255, 255, 255);
    width: 2.2rem;
    height: 2.2rem;
    z-index: 89;
    border-radius: 50%;
    scale: 0.2;
    transition: all 0.2s ease;

    i {
      color: rgb(255, 255, 255);
      transform: translate(-100%, 50%);
      transition: all 0.4s ease;
      font-size: 1.1rem;
    }
  }
}


#loader {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 99;

  .loader-cont {
    width: 100%;
    height: 100vh;
    position: absolute;
    z-index: 10;
    color: white;
    display: grid;
    place-content: center;
    font-size: 3rem;
    clip-path: inset(0 0 0 0);
  }

  .cont-2 {
    width: 100%;
    height: 100vh;
    background-color: black;
    position: absolute;
    z-index: 10;
    color: white;
    clip-path: inset(0 0 0 0);
    display: flex;
    justify-content: center;
    align-items: center;

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
    }

    video.loader-mobile {
      display: none;
    }

    .fade {
      z-index: inherit;
      margin: 0.3em;
      font-size: 2rem;
    }

    &::before {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      z-index: inherit;
      background-color: crimson;
      clip-path: var(--clip);
    }
  }

  .loader-vid {
    width: 100%;
    height: 100vh;
    position: absolute;
  }

  .loader-vid video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.Desktop {
  display: block;
}
.Mobile {
  display: none;
}
#ham-contents {
  width: 100%;
  height: 100vh;
  z-index: 33;
  position: fixed;
  top: 0;
  background-color: var(--bgd);

  .ham-contents-right {
    position: absolute;
    left: 50%;
    top: 75%;
    transform: translate(-50%, -50%);
    mix-blend-mode: screen;
    filter: drop-shadow(0 0 10px black);
    ul {
      list-style: none;
      li a {
        width: 100%;
        width: max-content;
      }
    }
  }

  .ham-contents-right-2 {
    position: absolute;
    right: 1%;
    top: 50%;
    height: fit-content;
    transform: translate(0, -50%);
    mix-blend-mode: difference;

    ul {
      list-style: none;
      font-family: dirt;
      li {
        @include flex-center(center, end);
        flex-direction: column;
        overflow: hidden;
        a {
          width: 100%;
          width: max-content;
          font-size: 2rem;
          text-transform: capitalize;
          color: #ebf5eb;
        }
      }
    }
  }

  .video-cont {
    width: 80%;
    height: 110%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    opacity: 0.8;

    div {
      mix-blend-mode: difference;

      h1 {
        font-size: 4rem;
        z-index: 2;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        white-space: nowrap;
        font-family: dirt;
        color: white;
      }
    }

    video {
      position: absolute;
      inset: 0;
    }
  }

  ul.ham-ul-1 {
    width: 50%;
    height: 100%;
    @include flex-center(center, start);
    flex-direction: column;
    padding: 1%;
    mix-blend-mode: difference;
    font-family: dirt;
    li {
      overflow: hidden;
      transform-origin: center left;
      a {
        font-size: 2.5rem;
        font-weight: 900;
        text-transform: lowercase;
        display: inline-block;
        text-shadow: 0 0 20px black;
        color: #ebf5eb;
      }
    }
  }
}

.ham-ul {
  width: fit-content;
  height: 2.5rem;
  position: relative;
  justify-content: end;
  position: fixed;
  top: 0;
  right: 1%;
  z-index: 97;
  @include flex-center(center, center);
  background-color: rgb(255, 255, 255);
  padding: 0.5em 1em;
  border-radius: 5rem;

  .hamburger-icon {
    width: 4rem;
    height: 20%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    cursor: pointer;

    .ham-1,
    .ham-2 {
      width: 100%;
      height: 2px;
      position: absolute;
      background-color: #000000;
    }

    .ham-2 {
      top: 100%;
    }
  }
}

section#page1 {
  width: 100%;
  height: 100vh;
  position: relative;
  // display: none;

  header {
    width: 100%;
    height: 11vh;
    position: absolute;
    top: 0;
    z-index: 10;
    font-family: dirt;
    mix-blend-mode: difference;
    background-color: rgb(0, 0, 0);
    padding: 0 1%;

    nav {
      width: 100%;
      height: 100%;
      align-items: center;
      display: flex;

      ul {
        display: flex;
        align-items: center;
        list-style: none;
        width: calc(100% / 3);

        &:nth-child(1) {
          li {
            overflow: hidden;
          }
        }

        &:nth-child(3) {
          justify-content: flex-end;
          display: none;
        }

        &:nth-child(2) {
          justify-content: center;
          gap: 5%;
          background-color: #000000;

          li a {
            font-size: 1.2rem;
            font-family: dirt;
            text-transform: lowercase;
            color: #ffffff;
          }
        }

        li a {
          color: #ffffff;
          font-size: 2rem;
          @include flex-center;
        }
      }
    }
  }

  .page1-mid {
    width: 100%;
    height: 89vh;
    top: 11vh;
    position: relative;

    .sub-text-up {
      position: absolute;
      top: 25%;
      left: 50%;
      width: max-content;
      transform: translate(-50%, 0);
      text-transform: capitalize;
      position: relative;
      border: 2px solid rgba($color: #0e0e0e, $alpha: 0.8);
      border-radius: 3rem;
      padding: 0.5em 2em;

      .🌚 {
        p {
          font-size: 1.3rem;

          font-weight: 500;
        }
      }

      &::before {
        content: "";
        @include aboslute(60%, 50%, 0, 0);
        width: var(--strike-through);
        transform-origin: left;
        height: 2px;
        background-color: var(--bgd);
      }

      .overlay-txt {
        width: max-content;
        position: absolute;
        padding: inherit;
        border-radius: inherit;
        border: inherit;
        color: #ffffff;
        background-color: var(--bgd);
        // clip-path: circle(0px at 50% 50%);
        opacity: 0;
        top: 0%;
        left: -1%;

        p {
          font-size: 1.2rem;
          font-weight: 500;

          span {
            font-family: dirt;
          }
        }
      }
    }

    .p1-mid-txt {
      position: absolute;
      top: 60%;
      width: max-content;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      align-items: center;

      h2 {
        font-size: 3rem;
        font-weight: 400;
        color: var(--bgd);
      }

      .sub-text {
        display: flex;
        align-items: center;

        p:not(.love-stacks p) {
          font-size: 1.4rem;
          color: rgba($color: #0e0e0e, $alpha: 0.5);
          font-weight: 600;

          span {
            color: crimson;

            &:nth-child(2) {
              font-family: dirt;
            }
          }
        }
      }

      .love-stacks {
        position: relative;
        width: 7rem;
        height: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;

        h4 {
          position: absolute;
          font-size: 1.4rem;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 900;
        }
      }
    }
  }
}

section#page2 {
  width: 100%;
  min-height: 100vh;
  background-color: var(--bgl);
  // display: none;

  .p2-top {
    width: 98%;
    padding: 1%;
    position: relative;
    height: 30vh;

    h2 {
      font-size: 4rem;
      text-align: left;
      text-transform: capitalize;
      line-height: 1;
      position: relative;
      font-family: heading;
      font-weight: 600;

      span {
        font-family: dirt;
        color: var(--crimson);
        text-transform: none;
      }

      &::before {
        content: "";
        position: absolute;
        bottom: -4%;
        right: 0;
        width: 100%;
        height: 2px;
        background-color: #0e0e0e;
      }
    }
  }

  .p2-mid {
    width: 98%;
    margin: 1%;
    display: flex;
    height: 70vh;
    position: relative;
    z-index: 3;
    padding-top: 5%;

    .p2-last {
      font-size: 1.3rem;
      position: absolute;
      bottom: 0;
      width: 98%;
      margin: 0 1%;
      font-weight: 600;

      span {
        font-family: dirt;
        color: var(--crimson);
      }
    }

    .p2-mid-left {
      width: 50%;
      height: 70%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      h4 {
        font-size: 2rem;
        text-transform: capitalize;
        padding: 1em 0;
        font-weight: 900;
      }

      ul {
        list-style: none;

        li {
          border-top: 1px solid var(--bgd);
          width: 90%;
          padding: 1em;
          display: flex;
          font-size: 1.3rem;
          justify-content: space-between;
          align-items: center;
          position: relative;
          text-align: center;
          overflow: hidden;

          &:hover {
            &::before {
              top: 0;
            }

            p,
            h3,
            i {
              color: var(--bgl);
            }
          }

          &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            top: 100%;
            left: 0;
            background-color: var(--bgd);
            z-index: 1;
            padding: 1em;

            transition: all 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86);
          }

          &:nth-child(3) {
            border-bottom: 1px solid var(--bgd);
          }

          p,
          h3,
          i {
            z-index: 2;
            font-weight: bold;
            color: #0e0e0ee1;
            transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
          }
        }
      }
    }

    .p2-mid-right {
      width: 50%;
      height: 70%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      // padding-bottom: 6%;
    

      h3 {
        font-size: 2rem;
        margin-bottom: 2%;
        line-height: 1;

        span {
          font-family: dirt;
          color: var(--crimson);
        }
      }

      p {
        font-weight: 600;
        font-size: 1.1rem;
        line-height: 1.3;
        letter-spacing: .4px;
        color: #0e0e0ee1;

        img {
          width: 1.2rem;
          height: 1.2rem;
        }

        span {
          font-family: dirt;
        }
      }
    }
  }
}

section#page3 {
  display: grid;
  place-content: center;
  width: 100%;
  min-height: 200vh;
  position: relative;
  margin-top: 10%;
  margin-bottom: 25%;
  font-family: dirt;
  background-color: var(--bgl);
  // display: none;
  position: relative;

  .container {
    width: fit-content;
    height: fit-content;
    position: absolute;
    left: 55%;
    transform: translate(-50%, 0);

    &:nth-child(1) {
      h2 {
        color: rgba(0, 0, 0, 0.158);
        overflow: hidden;
      }
    }

    &:nth-child(3) {
      h2 {
        color: var(--crimson);
      }
    }

    h2 {
      writing-mode: vertical-rl;
      font-size: 8rem;
      height: max-content;
      font-kerning: none;
      font-weight: 400;
    }
  }

  .container-4 {
    position: absolute;
    width: fit-content;
    height: fit-content;
    right: 45%;
    top: 15%;
    transform: translate(-50%, 0);

    #i10 {
      color: rgba(0, 0, 0, 0.158);
      overflow: hidden;
    }

    #i11 {
      color: var(--bgd);
    }

    #i12 {
      color: var(--crimson);
    }

    h2 {
      font-size: 8rem;
      writing-mode: vertical-rl;
      white-space: nowrap;
      font-weight: 400;
    }
  }
}

section#page4 {
  width: 100%;
  min-height: 100vh;
  position: relative;

  .p4-top {
    width: 98%;
    margin: 1%;

    h2 {
      font-size: 5.34rem;
      font-weight: bolder;

      span {
        font-family: dirt;
        font-style: italic;
        color: var(--crimson);
      }
    }
  }

  .p4-mid {
    width: 100%;
    padding: 5% 1%;
    position: relative;

    .information-card {
      width: max-content;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 9;
      text-transform: uppercase;
      line-height: 1;
      @include flex-center(center, center);
      flex-direction: column;
      mix-blend-mode: difference;
      pointer-events: none;

      span {
        overflow: hidden;

        h2 {
          font-size: 5rem;
          text-align: center;
          font-weight: 900;
          transform-origin: center left;
          color: var(--bgl);
          font-family: heading;
        }
      }
    }

    .p4-row-1,
    .p4-row-2,
    .p4-row-3 {
      display: flex;
      justify-content: center;
      gap: 34%;

      .works-card {
        width: 40%;
        position: relative;
        height: fit-content;
        height: 60vh;
        cursor: pointer;

        h2 {
          font-size: 2rem;
          display: none;
        }
        p {
          font-size: 1.4rem;
          font-weight: 400;
        }

        &:nth-child(2) {
          margin-top: 10%;
        }

        img {
          opacity: 0;
        }
      }

      .work-1 {
        video {
          opacity: 0;
          transition: all 0.5s ease;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 5;
        }

        &:hover video {
          opacity: 1;
        }
      }
    }
  }

  .img-cont {
    width: 20rem;
    margin-left: 10%;
    margin-top: 10%;
    // z-index: 100;
    // background-color: red;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: 0;
    }
  }
}
section#page5 {
  width: 100%;
  height: max-content;
  padding: 0 1%;
  overflow-x: hidden;
  margin-bottom: 20%;
  // display: none;

  .p5-top {
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    gap: 2rem;

    h2 {
      font-size: 4.5rem;
      text-transform: capitalize;
      font-weight: bolder;

      span.italic {
        font-style: italic;
        color: var(--crimson);
        font-family: dirt;
      }
      span {
        &:nth-child(2) {
          font-size: 10rem;
          font-weight: 400;
        }
      }
    }
  }

  .p5-mid {
    position: relative;
    width: 100%;
    height: max-content;

    .p5-row-1 {
      width: 100%;
      height: 100%;
      @include flex-center(space-between, center);
      padding: 0.5em 1em;
      position: relative;

      .p5-overlay {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: var(--crimson);
        color: var(--bgd);
        @include flex-center(center, center);
        clip-path: inset(50% 0 49.9999% 0);
        transition: all 0.3s ease;

        p {
          font-size: 2.3rem;
          text-transform: capitalize;
          font-weight: 600;
          text-shadow: 0 0 1rem #4846466c;

          span {
            font-family: dirt;
            text-transform: none;
            
            i {
              font-size: 2.4rem;
              font-weight: 900;
            }
          }
        }
      }

      &:hover .p5-overlay {
        clip-path: inset(0 0 0 0);
      }

      h3,
      i {
        &:nth-child(1) {
          font-size: 2rem;
          font-weight: 500;
        }
        font-size: 3rem;
        span {
          font-family: dirt;
          font-weight: 500;
        color: #0e0e0ee1;

        }
      }
    }
  }
  .p5-btm {
    h2 {
      font-size: 10rem;
      font-weight: 400;
    }
  }
}

section#page6 {
  width: 100%;
  min-height: 100vh;
  position: relative;
  // display: none;

  .p6-top {
    width: 98%;
    margin: 1%;
    height: fit-content;

    h2 {
      font-size: 4rem;

      img {
        width: 4rem;
        margin-top: 1%;
      }
      span {
        font-family: dirt;
        color: var(--crimson);
      }
    }
  }

  .p6-mid {
    width: 98%;
    margin: 10% 1% 1% 1%;
    min-height: 200vh;
    position: relative;
    display: flex;
    flex-direction: column;

    img {
      opacity: 0;
    }

    .goofy-cont {
      width: 50%;
      height: 100vh;
      display: block;

      .goofy-1 {
        width: 75%;
        height: 80%;
        overflow: hidden;

        aspect-ratio: 681/1280;
        // background-color: red;
        img {
          opacity: 0;
        }
      }
      .goofy-content-1 {
        margin: 2% 0 0 7.3%;
        width: fit-content;

        h3 {
          border-bottom: 2px solid var(--bgd);
          font-size: 2rem;
          position: relative;
          i {
            color: var(--crimson);
            font-family: dirt;
          }

          &::before {
            position: absolute;
            content: "Feet Reveal";
            top: 0;
            left: 0;
            background-color: var(--crimson);
            width: 100%;
            height: 100%;
            @include flex-center(space-evenly, center);
            clip-path: inset(100% 0% 0% 0);
            transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
          }

          &:hover::before {
            clip-path: inset(0% 0% 0% 0);
          }
        }
        p {
          font-size: 1.2rem;
        }
      }

      &:nth-child(2) {
        margin-left: 40%;
        width: 60%;
        display: block;

        .goofy-2 {
          width: 50%;
          height: 90%;
          overflow: hidden;

          img {
            opacity: 1;
          }
        }
        .goofy-content-2 {
          h3 {
            font-size: 2rem;
            border-bottom: 2px solid var(--bgd);
            width: fit-content;

            i {
              font-family: dirt;
              color: var(--crimson);
            }
          }
          p {
            font-size: 1.2rem;
          }
        }
      }
      &:nth-child(3) {
        margin: 5%;
        display: block;
        .goofy-3 {
          width: 70%;
          overflow: hidden;
          height: 90%;

          // aspect-ratio: 506/600;
          img {
            opacity: 1;
          }
        }
        .goofy-content-3 {
          h3 {
            font-size: 2rem;
          }
          p {
            border-top: 2px solid var(--bgd);
            font-size: 1.2rem;
            width: fit-content;
          }
        }
      }

      &:nth-child(4) {
        margin-left: 50%;
        .goofy-4 {
          width: 50%;
          height: 90%;
          overflow: hidden;

          img {
            opacity: 1;
            filter: saturate(1.2);
          }
        }

        .goofy-content-4 {
          h3 {
            font-size: 2rem;
            border-bottom: 2px solid var(--bgd);
            width: fit-content;
          }
          p {
            font-size: 1.2rem;
          }
        }
      }
    }
  }
}

section#page7 {
  width: 100%;
  min-height: 120vh;
  margin-top: 10%;
  position: relative;
  // display: none;
  // background-color: var(--bgd);

  .p7-top {
    width: 98%;
    color: var(--bgl);
    margin: 1%;
    padding: 5% 0 0 1%;
    height: fit-content;
    background-color: black;
    mix-blend-mode: difference;

    h2 {
      font-size: 4rem;
      line-height: 1;
      span {
        font-family: dirt;
        color: var(--teal);
        letter-spacing: 1px;
      }
    }
    p {
      width: 90%;
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 1;
      padding-top: 1em;
      padding-bottom: 1em;
      text-align: justify;
    }
  }

  .p7-mid {
    width: 100%;
    height: 90vh;
    position: relative;
    overflow: hidden;

    video {
      mix-blend-mode: difference;
      position: absolute;
    }
  }
}

section#page8 {
  width: 100%;
  height: 50vh;
  z-index: 4;
  @include flex-center(center, center);
  h2 {
    font-size: 4rem;
    text-align: center;
    line-height: 1;
    span {
      font-family: dirt;
      color: var(--crimson);
    }
  }
}

footer {
  position: relative;
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  z-index: 3;
  background: var(--bgd);

  .footer-contents {
    width: 100%;
    height: 80vh;
    position: absolute;
    bottom: 0;
    z-index: 1;
    font-family: dirt;

    .contacts {
      width: fit-content;
      height: fit-content;
      position: absolute;
      top: 2%;
      left: 1%;

      ul {
        padding: 1em;
        list-style: none;
      }
    }

    .footer-mid {
      color: white;
      text-transform: lowercase;
      position: absolute;
      bottom: 30vh;
      left: 50%;
      transform: translate(-50%, 0);
      display: grid;
      place-content: center;
      gap: 1em;
      width: 100%;
      text-align: center;

      p {
        font-size: 3vw;
        width: fit-content;
        height: fit-content;
        padding: 0.2em 0.5em;
        border-radius: 4rem;
        background-color: rgba($color: #0e0e0e, $alpha: 0.5);
      }

      a {
        text-shadow: 0 0 1rem #f9f9f9;
      }
    }

    .socials {
      width: fit-content;
      height: fit-content;
      position: absolute;
      top: 2%;
      right: 1%;
      z-index: 9;

      ul {
        padding: 1em;
        border-radius: 1rem;
        // background-color: rgba($color: #0e0e0e, $alpha: .5);
        text-shadow: 0 0 1rem #f9f9f9;
        display: flex;
        gap: 0.3rem;
        flex-direction: column;
        list-style: none;
        text-align: right;

        li {
          &:hover {
            a i {
              padding-right: 0.5em;
            }
          }

          a {
            font-size: 1.7vw;

            i {
              transition: all 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86);
            }
          }
        }
      }
    }

    .footer-btm {
      width: 100%;
      letter-spacing: 1rem;
      color: rgb(255, 255, 255);
      position: absolute;
      bottom: 0%;
      left: 50%;
      transform: translate(-50%, 0%);
      mix-blend-mode: difference;

      h1 {
        font-size: 9vw;
        text-align: center;
      }
    }
  }

  .container {
    width: 100%;
    height: 80vh;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0;
    align-items: center;
    background-color: #0e0e0e;
    z-index: -1;
  }
}

@media (max-width: 767px) {
  .Desktop {
    display: none;
  }
  .Mobile {
    display: block;
  }
  #ham-contents {
    .ham-contents-right {
      top: 30%;
      left: 50%;
      transform: translate(-50%, 0);
      height: 40%;
      @include flex-center(end, flex-end);
      ul {
        display: grid;
        place-content: center;
        width: 100%;
        li a button {
          font-size: 1rem;
          width: max-content;
        }
      }
    }

    .ham-contents-right-2 {
      top: 84%;
      left: 0;

      ul {
        @include flex-center(space-around, center);
        flex-direction: row-reverse;

        li a {
          color: #ebf5eb;
        }
      }
    }

    .video-cont {
      width: 100vw;
      height: 100%;

      div h1 {
        font-size: 3rem;
      }
    }

    ul.ham-ul-1 {
      width: 100%;
      height: fit-content;
      padding-top: 2em;

      .gallery-link {
        display: none;
      }
    }
  }

  #loader {
    .cont-2 {
      video.loader-mobile {
        display: block;
      }
    }
  }

  video.loader-desktop {
    display: none;
  }

  section#page1 header {
    height: 9vh;

    nav ul {
      width: 50%;

      &:nth-child(1) {
        li{
         a {
          font-size: 1.5rem;
        }}
       
      }

      &:nth-child(2),
      &:nth-child(3) {
        display: none;
      }

      &:nth-child(4) {
        display: flex;
        padding-right: 1%;
        .gallery-link {
          display: none;
        }
      }
    }
  }

  section#page2 {
    .p2-top {
      height: 20vh;

      h2 {
        font-size: 2.4rem;
      }
    }

    .p2-mid {
      flex-direction: column;

      .p2-last {
        display: none;
      }

      .p2-mid-left {
        width: 98%;
        margin: 1%;

        h4 {
          font-size: 2rem;
          padding: 1em 0;
        }

        ul {
          @include flex-center(center, center);
          flex-direction: column;

          li {
            font-size: 1rem;
            width: 100%;
          }
        }
      }

      .p2-mid-right {
        width: 98%;
        margin: 10% 1%;
        height: fit-content;

        h3 {
          font-size: 2rem;
          width: 98%;
          border-bottom: 2px solid var(--bgd);
        }

        p {
          font-size: 1.2rem;
          text-align: justify;
          width: 98%;
        }
      }
    }
  }

  section#page3 {
    min-height: 160vh;
    margin-bottom: 0;
    .container {
      left: 60%;
    }

    .container-4 {
      right: 35%;
      top: 10%;
    }
  }

  section#page4 {
    margin-bottom: 20%;
    .p4-top {
      h2 {
        font-size: 4rem;
        line-height: 1;
      }
    }

    .p4-mid {
      .information-card {
        display: none;
      }

      .p4-row-1,
      .p4-row-2,
      .p4-row-3 {
        flex-direction: column;
        @include flex-center(center, center);
        margin-bottom: 20%;
        gap: 5rem;

        .works-card {
          width: 100%;
          height: 50vh;
          @include flex-center(center, center);
          flex-direction: column;
          gap: 0;

          h2 {
            position: absolute;
            bottom: -10%;
            font-size: 2.4rem;
            display: inline-block;
          }

          p {
            position: absolute;
            bottom: -15%;
            font-size: 1.4rem;
          }
        }
      }
    }
  }

  section#page5 {
    .p5-top {
      gap: 0;

      h2 {
        font-size: 2.2rem;
        letter-spacing: -1px;

        &:nth-child(2) {
          font-size: 4rem;
          font-weight: 400;
        }
        span {
          font-size: 2rem;

          &:nth-child(2) {
            font-size: 3rem;
          }
        }
      }
    }

    .p5-mid {
      .p5-row-1 {
        h3 {
          font-size: 1.2rem !important;
          margin: 2.5% 0;

          span {
            font-size: 1.5rem;
          }
        }
        i {
          font-size: 1.2rem;
        }
        .p5-overlay {
          p {
            font-size: 1.3rem;
            word-spacing: -2px;

            span i {
              font-size: 1.3rem;
            }
          }
        }
      }
    }

    .p5-btm {
      h2 {
        font-size: 3rem;
        margin-left: 4%;
      }
    }
  }

  section#page6 {
    display: none;
  }

  section#page8 {

    h2 {
      font-size: 2.9rem;
      text-align: center;
    }
  }

  .footer-btm h1 {
    font-size: 15vw !important;
  }

  .footer-mid {
    p {
      font-size: 8vw !important;
    }

    a {
      font-size: 5vw;
    }
  }

  .socials {
    ul li a {
      font-size: 5vw !important;
    }
  }
}
