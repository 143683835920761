@font-face {
  font-family: dirt;
  src: url("Dirtyline.f3211332.ttf");
}

@font-face {
  font-family: satoshi;
  src: url("PPNeueMontreal-Book.9c48ae9f.ttf") format("truetype");
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: heading;
  src: url("PPNeueMontreal-Medium.969a2746.ttf") format("truetype");
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  background-color: var(--bgl);
  width: 100%;
  height: 100%;
  font-family: satoshi;
  font-size: 16px;
}

:root {
  --bgd: #0e0e0e;
  --bgl: #f9f9f9;
  --crimson: #dc143c;
  --teal: #23ebc3;
  --btn: -100%;
  --strike-through: 0%;
  --clip: inset(100% 0 0 0);
}

::selection {
  background-color: var(--crimson);
  color: var(--bgl);
  border-radius: 3rem;
}

body::-webkit-scrollbar {
  background-color: #555;
  width: .5rem;
}

body::-webkit-scrollbar-thumb {
  background-color: var(--crimson);
  border-radius: 1rem;
}

::-webkit-scrollbar:hover {
  background: #555;
}

#overlay {
  z-index: 2;
  background-color: #0000;
  position: fixed;
  inset: 0;
}

video, img {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

a {
  color: #fff;
  text-decoration: none;
}

canvas.webgl {
  z-index: 0;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
}

button#btn1 {
  filter: invert();
  width: fit-content;
  margin: 0;
  padding: .25em .5em;
}

button.nav-btn {
  filter: drop-shadow(0 0 1rem #f9f9f9);
  background-color: #0000;
  border: 1px solid #fff;
  border-radius: 3rem;
  outline: none;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
  padding: .5em 1em;
  font-family: dirt;
  font-size: 1.2rem;
  display: flex;
  position: relative;
  overflow: hidden;
}

button.nav-btn:hover p {
  color: #000;
}

button.nav-btn:hover span {
  background-color: #000;
  scale: 1;
}

button.nav-btn:hover i {
  transform: translate(0%);
}

button.nav-btn p {
  z-index: 2;
  color: #fff;
}

button.nav-btn:before {
  content: "";
  left: 0;
  bottom: var(--btn);
  background-color: #fff;
  width: 100%;
  height: 100%;
  position: absolute;
}

button.nav-btn span {
  z-index: 89;
  background-color: #fff;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 2.2rem;
  height: 2.2rem;
  transition: all .2s;
  display: flex;
  scale: .2;
}

button.nav-btn span i {
  color: #fff;
  font-size: 1.1rem;
  transition: all .4s;
  transform: translate(-100%, 50%);
}

#loader {
  z-index: 99;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
}

#loader .loader-cont {
  z-index: 10;
  color: #fff;
  clip-path: inset(0);
  place-content: center;
  width: 100%;
  height: 100vh;
  font-size: 3rem;
  display: grid;
  position: absolute;
}

#loader .cont-2 {
  z-index: 10;
  color: #fff;
  clip-path: inset(0);
  background-color: #000;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  display: flex;
  position: absolute;
}

#loader .cont-2 video {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
}

#loader .cont-2 video.loader-mobile {
  display: none;
}

#loader .cont-2 .fade {
  z-index: inherit;
  margin: .3em;
  font-size: 2rem;
}

#loader .cont-2:before {
  content: "";
  z-index: inherit;
  -webkit-clip-path: var(--clip);
  clip-path: var(--clip);
  background-color: #dc143c;
  width: 100%;
  height: 100%;
  position: absolute;
}

#loader .loader-vid {
  width: 100%;
  height: 100vh;
  position: absolute;
}

#loader .loader-vid video {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.Desktop {
  display: block;
}

.Mobile {
  display: none;
}

#ham-contents {
  z-index: 33;
  background-color: var(--bgd);
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
}

#ham-contents .ham-contents-right {
  mix-blend-mode: screen;
  filter: drop-shadow(0 0 10px #000);
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#ham-contents .ham-contents-right ul {
  list-style: none;
}

#ham-contents .ham-contents-right ul li a {
  width: max-content;
}

#ham-contents .ham-contents-right-2 {
  mix-blend-mode: difference;
  height: fit-content;
  position: absolute;
  top: 50%;
  right: 1%;
  transform: translate(0, -50%);
}

#ham-contents .ham-contents-right-2 ul {
  font-family: dirt;
  list-style: none;
}

#ham-contents .ham-contents-right-2 ul li {
  flex-direction: column;
  justify-content: center;
  align-items: end;
  display: flex;
  overflow: hidden;
}

#ham-contents .ham-contents-right-2 ul li a {
  text-transform: capitalize;
  color: #ebf5eb;
  width: max-content;
  font-size: 2rem;
}

#ham-contents .video-cont {
  z-index: -1;
  opacity: .8;
  width: 80%;
  height: 110%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#ham-contents .video-cont div {
  mix-blend-mode: difference;
}

#ham-contents .video-cont div h1 {
  z-index: 2;
  white-space: nowrap;
  color: #fff;
  font-family: dirt;
  font-size: 4rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#ham-contents .video-cont video {
  position: absolute;
  inset: 0;
}

#ham-contents ul.ham-ul-1 {
  mix-blend-mode: difference;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  width: 50%;
  height: 100%;
  padding: 1%;
  font-family: dirt;
  display: flex;
}

#ham-contents ul.ham-ul-1 li {
  transform-origin: 0;
  overflow: hidden;
}

#ham-contents ul.ham-ul-1 li a {
  text-transform: lowercase;
  text-shadow: 0 0 20px #000;
  color: #ebf5eb;
  font-size: 2.5rem;
  font-weight: 900;
  display: inline-block;
}

.ham-ul {
  z-index: 97;
  background-color: #fff;
  border-radius: 5rem;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: 2.5rem;
  padding: .5em 1em;
  display: flex;
  position: fixed;
  top: 0;
  right: 1%;
}

.ham-ul .hamburger-icon {
  cursor: pointer;
  flex-direction: column;
  justify-content: space-between;
  width: 4rem;
  height: 20%;
  display: flex;
  position: relative;
}

.ham-ul .hamburger-icon .ham-1, .ham-ul .hamburger-icon .ham-2 {
  background-color: #000;
  width: 100%;
  height: 2px;
  position: absolute;
}

.ham-ul .hamburger-icon .ham-2 {
  top: 100%;
}

section#page1 {
  width: 100%;
  height: 100vh;
  position: relative;
}

section#page1 header {
  z-index: 10;
  mix-blend-mode: difference;
  background-color: #000;
  width: 100%;
  height: 11vh;
  padding: 0 1%;
  font-family: dirt;
  position: absolute;
  top: 0;
}

section#page1 header nav {
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

section#page1 header nav ul {
  align-items: center;
  width: 33.3333%;
  list-style: none;
  display: flex;
}

section#page1 header nav ul:first-child li {
  overflow: hidden;
}

section#page1 header nav ul:nth-child(3) {
  justify-content: flex-end;
  display: none;
}

section#page1 header nav ul:nth-child(2) {
  background-color: #000;
  justify-content: center;
  gap: 5%;
}

section#page1 header nav ul:nth-child(2) li a {
  text-transform: lowercase;
  color: #fff;
  font-family: dirt;
  font-size: 1.2rem;
}

section#page1 header nav ul li a {
  color: #fff;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  display: flex;
}

section#page1 .page1-mid {
  width: 100%;
  height: 89vh;
  position: relative;
  top: 11vh;
}

section#page1 .page1-mid .sub-text-up {
  text-transform: capitalize;
  border: 2px solid #0e0e0ecc;
  border-radius: 3rem;
  width: max-content;
  padding: .5em 2em;
  position: relative;
  top: 25%;
  left: 50%;
  transform: translate(-50%);
}

section#page1 .page1-mid .sub-text-up .🌚 p {
  font-size: 1.3rem;
  font-weight: 500;
}

section#page1 .page1-mid .sub-text-up:before {
  content: "";
  width: var(--strike-through);
  transform-origin: 0;
  background-color: var(--bgd);
  height: 2px;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
}

section#page1 .page1-mid .sub-text-up .overlay-txt {
  padding: inherit;
  border-radius: inherit;
  border: inherit;
  color: #fff;
  background-color: var(--bgd);
  opacity: 0;
  width: max-content;
  position: absolute;
  top: 0%;
  left: -1%;
}

section#page1 .page1-mid .sub-text-up .overlay-txt p {
  font-size: 1.2rem;
  font-weight: 500;
}

section#page1 .page1-mid .sub-text-up .overlay-txt p span {
  font-family: dirt;
}

section#page1 .page1-mid .p1-mid-txt {
  align-items: center;
  width: max-content;
  display: flex;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
}

section#page1 .page1-mid .p1-mid-txt h2 {
  color: var(--bgd);
  font-size: 3rem;
  font-weight: 400;
}

section#page1 .page1-mid .p1-mid-txt .sub-text {
  align-items: center;
  display: flex;
}

section#page1 .page1-mid .p1-mid-txt .sub-text p:not(.love-stacks p) {
  color: #0e0e0e80;
  font-size: 1.4rem;
  font-weight: 600;
}

section#page1 .page1-mid .p1-mid-txt .sub-text p:not(.love-stacks p) span {
  color: #dc143c;
}

section#page1 .page1-mid .p1-mid-txt .sub-text p:not(.love-stacks p) span:nth-child(2) {
  font-family: dirt;
}

section#page1 .page1-mid .p1-mid-txt .love-stacks {
  justify-content: center;
  align-items: center;
  width: 7rem;
  height: 3rem;
  display: flex;
  position: relative;
}

section#page1 .page1-mid .p1-mid-txt .love-stacks h4 {
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  font-weight: 900;
  display: flex;
  position: absolute;
}

section#page2 {
  background-color: var(--bgl);
  width: 100%;
  min-height: 100vh;
}

section#page2 .p2-top {
  width: 98%;
  height: 30vh;
  padding: 1%;
  position: relative;
}

section#page2 .p2-top h2 {
  text-align: left;
  text-transform: capitalize;
  font-family: heading;
  font-size: 4rem;
  font-weight: 600;
  line-height: 1;
  position: relative;
}

section#page2 .p2-top h2 span {
  color: var(--crimson);
  text-transform: none;
  font-family: dirt;
}

section#page2 .p2-top h2:before {
  content: "";
  background-color: #0e0e0e;
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: -4%;
  right: 0;
}

section#page2 .p2-mid {
  z-index: 3;
  width: 98%;
  height: 70vh;
  margin: 1%;
  padding-top: 5%;
  display: flex;
  position: relative;
}

section#page2 .p2-mid .p2-last {
  width: 98%;
  margin: 0 1%;
  font-size: 1.3rem;
  font-weight: 600;
  position: absolute;
  bottom: 0;
}

section#page2 .p2-mid .p2-last span {
  color: var(--crimson);
  font-family: dirt;
}

section#page2 .p2-mid .p2-mid-left {
  flex-direction: column;
  justify-content: flex-end;
  width: 50%;
  height: 70%;
  display: flex;
}

section#page2 .p2-mid .p2-mid-left h4 {
  text-transform: capitalize;
  padding: 1em 0;
  font-size: 2rem;
  font-weight: 900;
}

section#page2 .p2-mid .p2-mid-left ul {
  list-style: none;
}

section#page2 .p2-mid .p2-mid-left ul li {
  border-top: 1px solid var(--bgd);
  text-align: center;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  padding: 1em;
  font-size: 1.3rem;
  display: flex;
  position: relative;
  overflow: hidden;
}

section#page2 .p2-mid .p2-mid-left ul li:hover:before {
  top: 0;
}

section#page2 .p2-mid .p2-mid-left ul li:hover p, section#page2 .p2-mid .p2-mid-left ul li:hover h3, section#page2 .p2-mid .p2-mid-left ul li:hover i {
  color: var(--bgl);
}

section#page2 .p2-mid .p2-mid-left ul li:before {
  content: "";
  background-color: var(--bgd);
  z-index: 1;
  width: 100%;
  height: 100%;
  padding: 1em;
  transition: all .5s cubic-bezier(.785, .135, .15, .86);
  position: absolute;
  top: 100%;
  left: 0;
}

section#page2 .p2-mid .p2-mid-left ul li:nth-child(3) {
  border-bottom: 1px solid var(--bgd);
}

section#page2 .p2-mid .p2-mid-left ul li p, section#page2 .p2-mid .p2-mid-left ul li h3, section#page2 .p2-mid .p2-mid-left ul li i {
  z-index: 2;
  color: #0e0e0ee1;
  font-weight: bold;
  transition: all .4s cubic-bezier(.785, .135, .15, .86);
}

section#page2 .p2-mid .p2-mid-right {
  flex-direction: column;
  justify-content: flex-end;
  width: 50%;
  height: 70%;
  display: flex;
}

section#page2 .p2-mid .p2-mid-right h3 {
  margin-bottom: 2%;
  font-size: 2rem;
  line-height: 1;
}

section#page2 .p2-mid .p2-mid-right h3 span {
  color: var(--crimson);
  font-family: dirt;
}

section#page2 .p2-mid .p2-mid-right p {
  letter-spacing: .4px;
  color: #0e0e0ee1;
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 1.3;
}

section#page2 .p2-mid .p2-mid-right p img {
  width: 1.2rem;
  height: 1.2rem;
}

section#page2 .p2-mid .p2-mid-right p span {
  font-family: dirt;
}

section#page3 {
  background-color: var(--bgl);
  place-content: center;
  width: 100%;
  min-height: 200vh;
  margin-top: 10%;
  margin-bottom: 25%;
  font-family: dirt;
  display: grid;
  position: relative;
}

section#page3 .container {
  width: fit-content;
  height: fit-content;
  position: absolute;
  left: 55%;
  transform: translate(-50%);
}

section#page3 .container:first-child h2 {
  color: #00000028;
  overflow: hidden;
}

section#page3 .container:nth-child(3) h2 {
  color: var(--crimson);
}

section#page3 .container h2 {
  writing-mode: vertical-rl;
  font-kerning: none;
  height: max-content;
  font-size: 8rem;
  font-weight: 400;
}

section#page3 .container-4 {
  width: fit-content;
  height: fit-content;
  position: absolute;
  top: 15%;
  right: 45%;
  transform: translate(-50%);
}

section#page3 .container-4 #i10 {
  color: #00000028;
  overflow: hidden;
}

section#page3 .container-4 #i11 {
  color: var(--bgd);
}

section#page3 .container-4 #i12 {
  color: var(--crimson);
}

section#page3 .container-4 h2 {
  writing-mode: vertical-rl;
  white-space: nowrap;
  font-size: 8rem;
  font-weight: 400;
}

section#page4 {
  width: 100%;
  min-height: 100vh;
  position: relative;
}

section#page4 .p4-top {
  width: 98%;
  margin: 1%;
}

section#page4 .p4-top h2 {
  font-size: 5.34rem;
  font-weight: bolder;
}

section#page4 .p4-top h2 span {
  color: var(--crimson);
  font-family: dirt;
  font-style: italic;
}

section#page4 .p4-mid {
  width: 100%;
  padding: 5% 1%;
  position: relative;
}

section#page4 .p4-mid .information-card {
  z-index: 9;
  text-transform: uppercase;
  mix-blend-mode: difference;
  pointer-events: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: max-content;
  line-height: 1;
  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

section#page4 .p4-mid .information-card span {
  overflow: hidden;
}

section#page4 .p4-mid .information-card span h2 {
  text-align: center;
  transform-origin: 0;
  color: var(--bgl);
  font-family: heading;
  font-size: 5rem;
  font-weight: 900;
}

section#page4 .p4-mid .p4-row-1, section#page4 .p4-mid .p4-row-2, section#page4 .p4-mid .p4-row-3 {
  justify-content: center;
  gap: 34%;
  display: flex;
}

section#page4 .p4-mid .p4-row-1 .works-card, section#page4 .p4-mid .p4-row-2 .works-card, section#page4 .p4-mid .p4-row-3 .works-card {
  cursor: pointer;
  width: 40%;
  height: 60vh;
  position: relative;
}

section#page4 .p4-mid .p4-row-1 .works-card h2, section#page4 .p4-mid .p4-row-2 .works-card h2, section#page4 .p4-mid .p4-row-3 .works-card h2 {
  font-size: 2rem;
  display: none;
}

section#page4 .p4-mid .p4-row-1 .works-card p, section#page4 .p4-mid .p4-row-2 .works-card p, section#page4 .p4-mid .p4-row-3 .works-card p {
  font-size: 1.4rem;
  font-weight: 400;
}

section#page4 .p4-mid .p4-row-1 .works-card:nth-child(2), section#page4 .p4-mid .p4-row-2 .works-card:nth-child(2), section#page4 .p4-mid .p4-row-3 .works-card:nth-child(2) {
  margin-top: 10%;
}

section#page4 .p4-mid .p4-row-1 .works-card img, section#page4 .p4-mid .p4-row-2 .works-card img, section#page4 .p4-mid .p4-row-3 .works-card img {
  opacity: 0;
}

section#page4 .p4-mid .p4-row-1 .work-1 video, section#page4 .p4-mid .p4-row-2 .work-1 video, section#page4 .p4-mid .p4-row-3 .work-1 video {
  opacity: 0;
  z-index: 5;
  transition: all .5s;
  position: absolute;
  top: 0;
  left: 0;
}

section#page4 .p4-mid .p4-row-1 .work-1:hover video, section#page4 .p4-mid .p4-row-2 .work-1:hover video, section#page4 .p4-mid .p4-row-3 .work-1:hover video {
  opacity: 1;
}

section#page4 .img-cont {
  width: 20rem;
  margin-top: 10%;
  margin-left: 10%;
}

section#page4 .img-cont img {
  -o-object-fit: cover;
  object-fit: cover;
  opacity: 0;
  width: 100%;
  height: 100%;
}

section#page5 {
  width: 100%;
  height: max-content;
  margin-bottom: 20%;
  padding: 0 1%;
  overflow-x: hidden;
}

section#page5 .p5-top {
  align-items: center;
  gap: 2rem;
  width: 100%;
  height: fit-content;
  display: flex;
}

section#page5 .p5-top h2 {
  text-transform: capitalize;
  font-size: 4.5rem;
  font-weight: bolder;
}

section#page5 .p5-top h2 span.italic {
  color: var(--crimson);
  font-family: dirt;
  font-style: italic;
}

section#page5 .p5-top h2 span:nth-child(2) {
  font-size: 10rem;
  font-weight: 400;
}

section#page5 .p5-mid {
  width: 100%;
  height: max-content;
  position: relative;
}

section#page5 .p5-mid .p5-row-1 {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: .5em 1em;
  display: flex;
  position: relative;
}

section#page5 .p5-mid .p5-row-1 .p5-overlay {
  content: "";
  background-color: var(--crimson);
  color: var(--bgd);
  clip-path: inset(50% 0 49.9999%);
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: all .3s;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

section#page5 .p5-mid .p5-row-1 .p5-overlay p {
  text-transform: capitalize;
  text-shadow: 0 0 1rem #4846466c;
  font-size: 2.3rem;
  font-weight: 600;
}

section#page5 .p5-mid .p5-row-1 .p5-overlay p span {
  text-transform: none;
  font-family: dirt;
}

section#page5 .p5-mid .p5-row-1 .p5-overlay p span i {
  font-size: 2.4rem;
  font-weight: 900;
}

section#page5 .p5-mid .p5-row-1:hover .p5-overlay {
  clip-path: inset(0);
}

section#page5 .p5-mid .p5-row-1 h3, section#page5 .p5-mid .p5-row-1 i {
  font-size: 3rem;
}

section#page5 .p5-mid .p5-row-1 h3:first-child, section#page5 .p5-mid .p5-row-1 i:first-child {
  font-size: 2rem;
  font-weight: 500;
}

section#page5 .p5-mid .p5-row-1 h3 span, section#page5 .p5-mid .p5-row-1 i span {
  color: #0e0e0ee1;
  font-family: dirt;
  font-weight: 500;
}

section#page5 .p5-btm h2 {
  font-size: 10rem;
  font-weight: 400;
}

section#page6 {
  width: 100%;
  min-height: 100vh;
  position: relative;
}

section#page6 .p6-top {
  width: 98%;
  height: fit-content;
  margin: 1%;
}

section#page6 .p6-top h2 {
  font-size: 4rem;
}

section#page6 .p6-top h2 img {
  width: 4rem;
  margin-top: 1%;
}

section#page6 .p6-top h2 span {
  color: var(--crimson);
  font-family: dirt;
}

section#page6 .p6-mid {
  flex-direction: column;
  width: 98%;
  min-height: 200vh;
  margin: 10% 1% 1%;
  display: flex;
  position: relative;
}

section#page6 .p6-mid img {
  opacity: 0;
}

section#page6 .p6-mid .goofy-cont {
  width: 50%;
  height: 100vh;
  display: block;
}

section#page6 .p6-mid .goofy-cont .goofy-1 {
  aspect-ratio: 681 / 1280;
  width: 75%;
  height: 80%;
  overflow: hidden;
}

section#page6 .p6-mid .goofy-cont .goofy-1 img {
  opacity: 0;
}

section#page6 .p6-mid .goofy-cont .goofy-content-1 {
  width: fit-content;
  margin: 2% 0 0 7.3%;
}

section#page6 .p6-mid .goofy-cont .goofy-content-1 h3 {
  border-bottom: 2px solid var(--bgd);
  font-size: 2rem;
  position: relative;
}

section#page6 .p6-mid .goofy-cont .goofy-content-1 h3 i {
  color: var(--crimson);
  font-family: dirt;
}

section#page6 .p6-mid .goofy-cont .goofy-content-1 h3:before {
  content: "Feet Reveal";
  background-color: var(--crimson);
  clip-path: inset(100% 0% 0% 0);
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: all .4s cubic-bezier(.785, .135, .15, .86);
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

section#page6 .p6-mid .goofy-cont .goofy-content-1 h3:hover:before {
  clip-path: inset(0% 0% 0% 0);
}

section#page6 .p6-mid .goofy-cont .goofy-content-1 p {
  font-size: 1.2rem;
}

section#page6 .p6-mid .goofy-cont:nth-child(2) {
  width: 60%;
  margin-left: 40%;
  display: block;
}

section#page6 .p6-mid .goofy-cont:nth-child(2) .goofy-2 {
  width: 50%;
  height: 90%;
  overflow: hidden;
}

section#page6 .p6-mid .goofy-cont:nth-child(2) .goofy-2 img {
  opacity: 1;
}

section#page6 .p6-mid .goofy-cont:nth-child(2) .goofy-content-2 h3 {
  border-bottom: 2px solid var(--bgd);
  width: fit-content;
  font-size: 2rem;
}

section#page6 .p6-mid .goofy-cont:nth-child(2) .goofy-content-2 h3 i {
  color: var(--crimson);
  font-family: dirt;
}

section#page6 .p6-mid .goofy-cont:nth-child(2) .goofy-content-2 p {
  font-size: 1.2rem;
}

section#page6 .p6-mid .goofy-cont:nth-child(3) {
  margin: 5%;
  display: block;
}

section#page6 .p6-mid .goofy-cont:nth-child(3) .goofy-3 {
  width: 70%;
  height: 90%;
  overflow: hidden;
}

section#page6 .p6-mid .goofy-cont:nth-child(3) .goofy-3 img {
  opacity: 1;
}

section#page6 .p6-mid .goofy-cont:nth-child(3) .goofy-content-3 h3 {
  font-size: 2rem;
}

section#page6 .p6-mid .goofy-cont:nth-child(3) .goofy-content-3 p {
  border-top: 2px solid var(--bgd);
  width: fit-content;
  font-size: 1.2rem;
}

section#page6 .p6-mid .goofy-cont:nth-child(4) {
  margin-left: 50%;
}

section#page6 .p6-mid .goofy-cont:nth-child(4) .goofy-4 {
  width: 50%;
  height: 90%;
  overflow: hidden;
}

section#page6 .p6-mid .goofy-cont:nth-child(4) .goofy-4 img {
  opacity: 1;
  filter: saturate(1.2);
}

section#page6 .p6-mid .goofy-cont:nth-child(4) .goofy-content-4 h3 {
  border-bottom: 2px solid var(--bgd);
  width: fit-content;
  font-size: 2rem;
}

section#page6 .p6-mid .goofy-cont:nth-child(4) .goofy-content-4 p {
  font-size: 1.2rem;
}

section#page7 {
  width: 100%;
  min-height: 120vh;
  margin-top: 10%;
  position: relative;
}

section#page7 .p7-top {
  color: var(--bgl);
  mix-blend-mode: difference;
  background-color: #000;
  width: 98%;
  height: fit-content;
  margin: 1%;
  padding: 5% 0 0 1%;
}

section#page7 .p7-top h2 {
  font-size: 4rem;
  line-height: 1;
}

section#page7 .p7-top h2 span {
  color: var(--teal);
  letter-spacing: 1px;
  font-family: dirt;
}

section#page7 .p7-top p {
  text-align: justify;
  width: 90%;
  padding-top: 1em;
  padding-bottom: 1em;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1;
}

section#page7 .p7-mid {
  width: 100%;
  height: 90vh;
  position: relative;
  overflow: hidden;
}

section#page7 .p7-mid video {
  mix-blend-mode: difference;
  position: absolute;
}

section#page8 {
  z-index: 4;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50vh;
  display: flex;
}

section#page8 h2 {
  text-align: center;
  font-size: 4rem;
  line-height: 1;
}

section#page8 h2 span {
  color: var(--crimson);
  font-family: dirt;
}

footer {
  z-index: 3;
  background: var(--bgd);
  align-items: flex-end;
  width: 100%;
  height: 80vh;
  display: flex;
  position: relative;
  overflow: hidden;
}

footer .footer-contents {
  z-index: 1;
  width: 100%;
  height: 80vh;
  font-family: dirt;
  position: absolute;
  bottom: 0;
}

footer .footer-contents .contacts {
  width: fit-content;
  height: fit-content;
  position: absolute;
  top: 2%;
  left: 1%;
}

footer .footer-contents .contacts ul {
  padding: 1em;
  list-style: none;
}

footer .footer-contents .footer-mid {
  color: #fff;
  text-transform: lowercase;
  text-align: center;
  place-content: center;
  gap: 1em;
  width: 100%;
  display: grid;
  position: absolute;
  bottom: 30vh;
  left: 50%;
  transform: translate(-50%);
}

footer .footer-contents .footer-mid p {
  background-color: #0e0e0e80;
  border-radius: 4rem;
  width: fit-content;
  height: fit-content;
  padding: .2em .5em;
  font-size: 3vw;
}

footer .footer-contents .footer-mid a {
  text-shadow: 0 0 1rem #f9f9f9;
}

footer .footer-contents .socials {
  z-index: 9;
  width: fit-content;
  height: fit-content;
  position: absolute;
  top: 2%;
  right: 1%;
}

footer .footer-contents .socials ul {
  text-shadow: 0 0 1rem #f9f9f9;
  text-align: right;
  border-radius: 1rem;
  flex-direction: column;
  gap: .3rem;
  padding: 1em;
  list-style: none;
  display: flex;
}

footer .footer-contents .socials ul li:hover a i {
  padding-right: .5em;
}

footer .footer-contents .socials ul li a {
  font-size: 1.7vw;
}

footer .footer-contents .socials ul li a i {
  transition: all .5s cubic-bezier(.785, .135, .15, .86);
}

footer .footer-contents .footer-btm {
  letter-spacing: 1rem;
  color: #fff;
  mix-blend-mode: difference;
  width: 100%;
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%);
}

footer .footer-contents .footer-btm h1 {
  text-align: center;
  font-size: 9vw;
}

footer .container {
  z-index: -1;
  background-color: #0e0e0e;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80vh;
  display: flex;
  position: absolute;
  bottom: 0;
}

@media (width <= 767px) {
  .Desktop {
    display: none;
  }

  .Mobile {
    display: block;
  }

  #ham-contents .ham-contents-right {
    justify-content: end;
    align-items: flex-end;
    height: 40%;
    display: flex;
    top: 30%;
    left: 50%;
    transform: translate(-50%);
  }

  #ham-contents .ham-contents-right ul {
    place-content: center;
    width: 100%;
    display: grid;
  }

  #ham-contents .ham-contents-right ul li a button {
    width: max-content;
    font-size: 1rem;
  }

  #ham-contents .ham-contents-right-2 {
    top: 84%;
    left: 0;
  }

  #ham-contents .ham-contents-right-2 ul {
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: center;
    display: flex;
  }

  #ham-contents .ham-contents-right-2 ul li a {
    color: #ebf5eb;
  }

  #ham-contents .video-cont {
    width: 100vw;
    height: 100%;
  }

  #ham-contents .video-cont div h1 {
    font-size: 3rem;
  }

  #ham-contents ul.ham-ul-1 {
    width: 100%;
    height: fit-content;
    padding-top: 2em;
  }

  #ham-contents ul.ham-ul-1 .gallery-link {
    display: none;
  }

  #loader .cont-2 video.loader-mobile {
    display: block;
  }

  video.loader-desktop {
    display: none;
  }

  section#page1 header {
    height: 9vh;
  }

  section#page1 header nav ul {
    width: 50%;
  }

  section#page1 header nav ul:first-child li a {
    font-size: 1.5rem;
  }

  section#page1 header nav ul:nth-child(2), section#page1 header nav ul:nth-child(3) {
    display: none;
  }

  section#page1 header nav ul:nth-child(4) {
    padding-right: 1%;
    display: flex;
  }

  section#page1 header nav ul:nth-child(4) .gallery-link {
    display: none;
  }

  section#page2 .p2-top {
    height: 20vh;
  }

  section#page2 .p2-top h2 {
    font-size: 2.4rem;
  }

  section#page2 .p2-mid {
    flex-direction: column;
  }

  section#page2 .p2-mid .p2-last {
    display: none;
  }

  section#page2 .p2-mid .p2-mid-left {
    width: 98%;
    margin: 1%;
  }

  section#page2 .p2-mid .p2-mid-left h4 {
    padding: 1em 0;
    font-size: 2rem;
  }

  section#page2 .p2-mid .p2-mid-left ul {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  section#page2 .p2-mid .p2-mid-left ul li {
    width: 100%;
    font-size: 1rem;
  }

  section#page2 .p2-mid .p2-mid-right {
    width: 98%;
    height: fit-content;
    margin: 10% 1%;
  }

  section#page2 .p2-mid .p2-mid-right h3 {
    border-bottom: 2px solid var(--bgd);
    width: 98%;
    font-size: 2rem;
  }

  section#page2 .p2-mid .p2-mid-right p {
    text-align: justify;
    width: 98%;
    font-size: 1.2rem;
  }

  section#page3 {
    min-height: 160vh;
    margin-bottom: 0;
  }

  section#page3 .container {
    left: 60%;
  }

  section#page3 .container-4 {
    top: 10%;
    right: 35%;
  }

  section#page4 {
    margin-bottom: 20%;
  }

  section#page4 .p4-top h2 {
    font-size: 4rem;
    line-height: 1;
  }

  section#page4 .p4-mid .information-card {
    display: none;
  }

  section#page4 .p4-mid .p4-row-1, section#page4 .p4-mid .p4-row-2, section#page4 .p4-mid .p4-row-3 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5rem;
    margin-bottom: 20%;
    display: flex;
  }

  section#page4 .p4-mid .p4-row-1 .works-card, section#page4 .p4-mid .p4-row-2 .works-card, section#page4 .p4-mid .p4-row-3 .works-card {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0;
    width: 100%;
    height: 50vh;
    display: flex;
  }

  section#page4 .p4-mid .p4-row-1 .works-card h2, section#page4 .p4-mid .p4-row-2 .works-card h2, section#page4 .p4-mid .p4-row-3 .works-card h2 {
    font-size: 2.4rem;
    display: inline-block;
    position: absolute;
    bottom: -10%;
  }

  section#page4 .p4-mid .p4-row-1 .works-card p, section#page4 .p4-mid .p4-row-2 .works-card p, section#page4 .p4-mid .p4-row-3 .works-card p {
    font-size: 1.4rem;
    position: absolute;
    bottom: -15%;
  }

  section#page5 .p5-top {
    gap: 0;
  }

  section#page5 .p5-top h2 {
    letter-spacing: -1px;
    font-size: 2.2rem;
  }

  section#page5 .p5-top h2:nth-child(2) {
    font-size: 4rem;
    font-weight: 400;
  }

  section#page5 .p5-top h2 span {
    font-size: 2rem;
  }

  section#page5 .p5-top h2 span:nth-child(2) {
    font-size: 3rem;
  }

  section#page5 .p5-mid .p5-row-1 h3 {
    margin: 2.5% 0;
    font-size: 1.2rem !important;
  }

  section#page5 .p5-mid .p5-row-1 h3 span {
    font-size: 1.5rem;
  }

  section#page5 .p5-mid .p5-row-1 i {
    font-size: 1.2rem;
  }

  section#page5 .p5-mid .p5-row-1 .p5-overlay p {
    word-spacing: -2px;
    font-size: 1.3rem;
  }

  section#page5 .p5-mid .p5-row-1 .p5-overlay p span i {
    font-size: 1.3rem;
  }

  section#page5 .p5-btm h2 {
    margin-left: 4%;
    font-size: 3rem;
  }

  section#page6 {
    display: none;
  }

  section#page8 h2 {
    text-align: center;
    font-size: 2.9rem;
  }

  .footer-btm h1 {
    font-size: 15vw !important;
  }

  .footer-mid p {
    font-size: 8vw !important;
  }

  .footer-mid a {
    font-size: 5vw;
  }

  .socials ul li a {
    font-size: 5vw !important;
  }
}
/*# sourceMappingURL=index.2041bdf7.css.map */
